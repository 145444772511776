import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import { NextImage } from "../index";

import { MiniCardItemWrapper, TitleWrapper, Description } from "./styles";

const Link = dynamic(() => import("../link/link"));

const Title = ({ children }) => {
  return (
    <TitleWrapper>
      <p>{renderLineBreaks(children)}</p>
    </TitleWrapper>
  );
};


const MiniCard = ({
  title,
  description,
  icon,
  hideOnMobile,
  link,
}) => {
  const hasLink = !!link;
  const cardProps = {
    title,
    icon,
    hideOnMobile,
  };

  return (
    <MiniCardItemWrapper {...cardProps} hasLink={hasLink}>
      {icon && <NextImage media={icon} alt={title} />}
      {(title) && (
        <Title
          icon={icon}
        >
          {title}
        </Title>

      )}
      <Description>
        {description}
      </Description>
    </MiniCardItemWrapper>
  );
};

export const MiniCardItem = (props) => {
  const { link } = props;
  const hasLink = !!link;

  return hasLink ? (
    <Link {...link} noUnderline>
      <MiniCard {...props} />
    </Link>
  ) : (
    <MiniCard {...props} />
  );
};

export default MiniCardItem;
