import styled, { css } from "styled-components";

export const MiniCardItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacingM);
  border: 1px solid #CCE0DF;
  border-radius: var(--radiusL);
  background-color: transparent;
  align-items: start;
  gap: var(--spacingS);
  
  > img {
    width: auto;
    height: 56px;
    object-fit:contain;
    text-align: left;
  }

  ${({ onlyIcon }) =>
    onlyIcon &&
    css`
      > img {
        height: auto;
        max-width: 120px;
      }
    `}


  ${({ hasLink }) =>
    hasLink &&
    css`
      transition: 0.5s;
      :hover {
        background-color: var(--gray200);
      }
    `}

  @media screen and (max-width: 1128px) {
    ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
        display: none;
      `}
  }
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  max-width: 100%;
  overflow: hidden;

  > p {
    margin: 0px;
    font-size: var(--textM);
    line-height: var(--lineHeightS);
    font-weight: bold;
    margin-bottom: var(--spacingXxs);
  }

  ${({ hasIcon }) =>
    !hasIcon &&
    css`
      -webkit-line-clamp: 4;
    `}

`;


export const Description = styled.p`
  font-size: 16px;
  flex: auto;
`

export default MiniCardItemWrapper;
